var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: "Add Integration Settings" } },
    [
      _c("vx-card", [
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Setting Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:25",
                    expression: "'required|max:25'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Setting Name" },
                model: {
                  value: _vm.form.settingName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "settingName", $$v)
                  },
                  expression: "form.settingName",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Setting Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Setting Value")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:500",
                    expression: "'required|max:500'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Setting Value" },
                model: {
                  value: _vm.form.settingValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "settingValue", $$v)
                  },
                  expression: "form.settingValue",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Setting Value"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "lg:float-left mt-4" }, [
          _c("span", { staticClass: "text-sm text-danger" }, [
            _vm._v("*Required Field"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "mt-4 mr-4",
                attrs: { type: "border", color: "danger" },
                on: { click: _vm.handleCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              { staticClass: "mt-4", on: { click: _vm.onSubmit } },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }